import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AccountingService } from 'src/app/modules/accounting/accounting.service';
import { ProjectsService } from 'src/app/modules/projects/projects.service';
import { SubscriptionService } from 'src/app/modules/settings/settings-modules/subscription/subscription.service';
import { setSubscriptionPlans } from 'src/app/store/actions/subscription.actions';
import {
  UpdateAdminLimit,
  UpdateBusinessLimit,
  UpdateStoreLimit,
  UpdateSupportEmailLimit,
  UpdateTransactionLimit,
} from 'src/app/store/actions/usage.action';
import { User } from 'src/app/store/models/user';
import { RootReducerState } from 'src/app/store/reducers';
import { selectAccounts } from 'src/app/store/selectors/account.selector';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import {
  selectUser,
  selectUserBusinesses,
} from 'src/app/store/selectors/user.selectors';
import { BusinessService } from '../../services/business.service';
import { UserService } from '../../services/user.service';
import { getSymbolFromCode } from 'currency-code-symbol-map';
import { getCurrencySymbol } from '@angular/common';
import { LanguageService } from '../../services/language.service';
import { selectUsage } from 'src/app/store/selectors/usage.selector';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { NumberService } from '../../services/number.service';
import { selectUserSubscription } from 'src/app/store/selectors/subscription.selectors';
import { ChatsService } from 'src/app/modules/chats/chats.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  constructor(
    public router: Router,
    private store: Store<RootReducerState>,
    private userService: UserService,
    private spinner: NgxSpinnerService,
    private businessService: BusinessService,
    private subscriptionService: SubscriptionService,
    private projectService: ProjectsService,
    private accountingService: AccountingService,
    private toaster: ToastrService,
    private numberService: NumberService,
    private chatService: ChatsService,
    private languageService: LanguageService
  ) {
    this.user$ = this.store.pipe(select(selectUser));
    this.businesses$ = this.store.pipe(select(selectUserBusinesses));
    this.business$ = this.store.pipe(select(selectBusiness));
    this.account$ = this.store.pipe(select(selectAccounts));
    this.usage$ = store.pipe(select(selectUsage));
    this.subscription$ = store.pipe(select(selectUserSubscription));
  }

  @Input() primary = false;
  @Input() plus = false;

  user$: Observable<any>;
  businesses$: Observable<any>;
  business$: Observable<any>;
  account$: Observable<any>;
  subscription$: Observable<any>;
  usage$: Observable<any>;
  unsubscribe$ = new Subject();
  open = false;
  toggleBussiness = false;
  selectedBusiness = {
    businessId: {
      _id: localStorage.getItem('selectedBusiness') || '',
      companyName: '',
    },
  };
  baseURL = environment.apiBaseURL;
  logo = 'assets/images/logo.png';
  logo2 = 'assets/images/Logo-white.png';
  storedLang = 'en';
  arabicSelected = false;
  starterPlan: boolean = false;
  haveStoragePlan: boolean = true;
  userSubscriptions = [];
  userId: any;
  chatUpdated: any;
  chatUpdateSubscription: any;
  sendUnreadCount: any;
  markAsReadCount: any;
  taskUpateSubscribe: any;
  taskUpateCount: any;
  fileUpdateCount: any;
  taskCountNumber: any;
  fileCountNumber: any;
  getAllNotifications: any;
  businessId: any;

  ngOnInit(): void {
    let route = this.router.url.split('/')[2];
    console.log('events', this.router.url);

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((events) => {
        // const allUsers = JSON.parse(localStorage.getItem('chatUpdates'));
        // console.log('events[url]1234', this.chatUpdated, allUsers);

        // if(allUsers?.includes(this.userId) && events['url'] === '/chats'){
        //   setTimeout(() => {
        //     this.chatUpdated = false;
        //   }, 10000);
        //   const index = allUsers.indexOf(this.userId);
        //   allUsers.splice(index, 1);
        //   localStorage.setItem('chatUpdates', JSON.stringify(allUsers));
        //   console.log('events[url]1234', this.chatUpdated, allUsers);
        // }

        if (events['url'] !== '/auth/select-business') {
          localStorage.setItem('currentLocation', events['url']);
        }
        route = this.router.url.split('/')[2];
        this.toggleBarIndicatorFor(route);
      });
    setTimeout(() => {
      this.toggleBarIndicatorFor(route);
    }, 10);

    this.user$.pipe(takeUntil(this.unsubscribe$)).subscribe((user) => {
      this.spinner.show();
      let businessId = localStorage.getItem('selectedBusiness');
      this.userService.getUserDetails(businessId ?? '').subscribe(
        (userResp) => {
          this.spinner.hide();
          this.userId = userResp.data._id;
          if (this.userId && this.businessId) {
            this.chatService.emit('sendUnreadCount', {
              userId: this.userId,
              businessId: this.businessId,
            });
          }
          // const allUsers = JSON.parse(localStorage.getItem('chatUpdates'));
          // this.chatUpdated = allUsers?.includes(this.userId)
          // console.log('events[url]1234', allUsers, this.userId, this.chatUpdated);

          if (userResp?.data?.subscription?.planName === 'Starter') {
            this.starterPlan = true;
          } else {
            this.starterPlan = false;
          }
          this.userSubscriptions = userResp?.data?.subscription?.subscriptions;
          if (
            !this.userSubscriptions?.find((sub) => sub?.subHost === 'Storage')
          ) {
            this.haveStoragePlan = false;
          }
          userResp.data['currentUsage'] = userResp?.currentUsage;
          if (!user.token) {
            this.userService.checkUserSubscription(userResp?.data);
            this.userService.onGetUserDetailsSuccess(userResp?.data);
          }
          console.log('set user subs', userResp?.data);
          this.userService.setUserLimits(userResp?.data);
        },
        (error) => {
          this.spinner.hide();
          this.logout();
        }
      );
    });
    this.business$.pipe(takeUntil(this.unsubscribe$)).subscribe((business) => {
      const businessId = business?.businessId?._id;
      this.businessId = business?.businessId?._id;
      if (businessId) {
        this.loadStores(businessId);
        this.selectedBusiness.businessId._id = business?.businessId?._id;
        this.numberService.currency.next(business?.businessId?.currency);
        const decimalSize = business?.businessId?.decimalSize ?? 2;
        this.numberService.decimalSize.next(decimalSize);
        this.numberService.number.next(`1.${decimalSize}-${decimalSize}`);
        this.accountingService.getAllAccounts(businessId).subscribe((resp) => {
          if (resp.success) {
            this.accountingService.setAccountsInStore(resp.data);
          }
        });
        this.projectService.getAllProjects(businessId).subscribe((resp) => {
          if (resp.success) {
            this.projectService.setProjectsInStore(resp.data);
          }
        });
        this.businessService.getUsage(businessId).subscribe((resp) => {
          if (resp?.success) {
            const { currentUses, susbcriptionLimit } = resp?.data;
            this.store.dispatch(
              UpdateTransactionLimit({
                transactionLimit: currentUses?.transactionLimit,
                subscriptionTransactionLimit:
                  susbcriptionLimit?.transactionLimit,
              })
            );
            this.store.dispatch(
              UpdateStoreLimit({
                storeLimit: currentUses?.storeLimit,
                subscriptionStoreLimit: susbcriptionLimit?.storeLimit,
              })
            );
            this.store.dispatch(
              UpdateSupportEmailLimit({
                supportEmail: currentUses?.supportEmail,
                subscriptionSupportEmail: susbcriptionLimit?.supportEmail,
              })
            );
          }
        });
      }
    });
    this.businesses$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((businesses: Array<any>) => {
        if (businesses.length > 0) {
          let index = businesses.findIndex(
            (business) =>
              business.businessId._id === this.selectedBusiness.businessId._id
          );
          if (index < 0) {
            index = 0;
          }
          const currencySymbol = getCurrencySymbol(
            businesses[index].businessId.currency,
            'narrow'
          );
          const data = {
            ...businesses[index],
            businessId: {
              ...businesses[index].businessId,
              currencySymbol,
            },
          };
          this.businessService.setBusiness(data);
        }
      });

    this.subscriptionService.getPlans().subscribe((resp) => {
      if (resp.success) {
        this.store.dispatch(
          setSubscriptionPlans({ subscriptionPlans: [...resp.data] })
        );
      }
    });

    if (localStorage.getItem('NuMetric|lang')) {
      this.storedLang = localStorage.getItem('NuMetric|lang');
      this.storedLang == 'en'
        ? (this.arabicSelected = false)
        : (this.arabicSelected = true);
    } else {
      localStorage.setItem('NuMetric|lang', 'en');
      this.arabicSelected = false;
    }

    this.getSubscription();

    this.chatUpdateSubscription = this.chatService
      .listen('anyUpdate')
      .subscribe((data) => {
        // console.log('allNotifications', data, this.router.url, this.chatUpdated);
        const item = data.chatDetails.chatUsers.find(
          (item) => item._id === this.userId
        );
        this.chatService.emit('sendUnreadCount', {
          userId: this.userId,
          businessId: this.businessId,
        });
        if (
          item &&
          data.sendBy._id !== this.userId &&
          this.router.url !== '/chats'
        ) {
          console.log('isItRunning', data);

          this.toaster.info(
            `${
              data.sendBy.firstName + ' ' + data.sendBy.lastName
            } has sent a message in the ${
              data.chatDetails.chatType === 'Individual'
                ? 'Personal'
                : data.chatDetails.channelGroupName
            } chat`
          );
          //   let users = JSON.parse(localStorage.getItem('chatUpdates'));
          //   console.log("avilable", users)
          //   if(users && !users.includes(this.userId)){
          //     users.push(this.userId);
          //   } else {
          //     users = [];
          //     users.push(this.userId);
          //   }
          //   this.chatUpdated = true;
          //   localStorage.setItem('chatUpdates', JSON.stringify(users));
        }
      });
    console.log('okayokay');

    this.sendUnreadCount = this.chatService
      .listen('sendUnreadCount')
      .subscribe((data) => {
        console.log('sendUnreadCount', data);
        if (data.userId === this.userId) {
          this.chatUpdated = data.data.reduce(
            (n, { unreadCount }) => n + unreadCount,
            0
          );
          console.log('chatUpdated', this.chatUpdated);
        }
      });
    this.markAsReadCount = this.chatService
      .listen('markAsRead')
      .subscribe((data) => {
        // console.log('sendUnreadCount', data);
        if (data === this.userId) {
          setTimeout(() => {
            this.chatService.emit('sendUnreadCount', {
              userId: this.userId,
              businessId: this.businessId,
            });
          }, 2000);
        }
      });
    this.taskUpateSubscribe = this.chatService
      .listen('notification')
      .subscribe((data) => {
        console.log('sendUnreadCount', data);
        if (
          data.toUser === this.userId &&
          (data.entityType === 'taskReminder' ||
            data.entityType === 'taskAssigned')
        ) {
          this.toaster.info(data.message);
        } else if (
          data.toUser === this.userId &&
          data.entityType === 'sharedFile'
        ) {
          this.toaster.info(data.message);
        }
        setTimeout(() => {
          this.chatService.emit('getAllNotifications', this.userId);
        }, 1000);
      });
    this.taskUpateCount = this.chatService
      .listen('notificationUnreadCount')
      .subscribe((data) => {
        console.log('notificationUnreadCount', data);
        const count = data.filter(
          (item) =>
            item.toUser.includes(this.userId) &&
            item.businessId === this.businessId
        );
        this.taskCountNumber = count.length;
      });
    this.fileUpdateCount = this.chatService
      .listen('getAllNotifications')
      .subscribe((data) => {
        console.log('getAllNotificationssidebar', data);
        const count = data.filter(
          (item) =>
            (item.toUser?.includes(this.userId) ||
              item.toUser === this.userId) &&
            item.entityType === 'sharedFile' &&
            item.seen === false &&
            item.businessId === this.businessId
        );
        // console.log('getAllNotificationssidebar1234', count);
        this.fileCountNumber = count.length;
      });
  }

  loadStores(businessId): void {
    this.businessService
      .getStores(businessId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((resp) => {
        if (resp?.success) {
          console.log(resp?.data, 'data');
          this.businessService.setStore(resp?.data);
        }
      });
  }

  getSubscription(): void {
    this.subscription$.subscribe((subs) => {
      if (subs?.planName === 'Invoicing') {
        this.logo = 'assets/images/invoicing.svg';
        this.logo2 = 'assets/images/invoicingWhite.svg';
        // this.logo = 'assets/images/Group 4653.svg'
      }
    });
  }

  logout(): void {
    this.spinner.show();
    this.userService.logoutAPI().subscribe(
      (resp) => {
        this.spinner.hide();
        if (resp.success) {
          this.userService.logout();
        }
      },
      (error) => {
        this.spinner.hide();
        console.log(error);
        this.toaster.error('Something went wrong');
      }
    );
  }

  selectBusiness(business): void {
    this.spinner.show();
    const currencySymbol = getCurrencySymbol(
      business.businessId.currency,
      'narrow'
    );
    const data = {
      ...business,
      businessId: {
        ...business.businessId,
        currencySymbol,
      },
    };
    localStorage.setItem('selectedBusiness', business?.businessId?._id);
    this.accountingService
      .getAllAccounts(business.businessId._id)
      .subscribe((resp) => {
        if (resp.success) {
          this.accountingService.setAccountsInStore(resp.data);
        }
      });
    this.projectService
      .getAllProjects(business.businessId._id)
      .subscribe((resp) => {
        if (resp.success) {
          this.projectService.setProjectsInStore(resp.data);
        }
      });
    this.businessService.getUsage(business.businessId._id).subscribe((resp) => {
      if (resp?.success) {
        const { currentUses, susbcriptionLimit } = resp?.data;
        this.store.dispatch(
          UpdateTransactionLimit({
            transactionLimit: currentUses?.transactionLimit,
            subscriptionTransactionLimit: susbcriptionLimit?.transactionLimit,
          })
        );
        this.store.dispatch(
          UpdateSupportEmailLimit({
            supportEmail: currentUses?.supportEmail,
            subscriptionSupportEmail: susbcriptionLimit?.supportEmail,
          })
        );
      }
    });
    this.businessService.setBusiness(data);
    this.selectedBusiness = JSON.parse(JSON.stringify(business));
    this.spinner.hide();
    this.router.navigate(['/dashboard']);
  }

  toggleBarIndicatorFor(routeName): void {
    const salesBar = document.getElementById('sales-bar');
    const subscriptionBar = document.getElementById('subscription-bar');
    const accountingBar = document.getElementById('accounting-bar');

    if (routeName === 'sales') {
      if (salesBar) {
        salesBar.style.opacity = '1';
      }
    } else {
      if (salesBar) {
        salesBar.style.opacity = '0';
      }
    }
    if (routeName === 'subscription') {
      if (subscriptionBar) {
        subscriptionBar.style.opacity = '1';
      }
    } else {
      if (subscriptionBar) {
        subscriptionBar.style.opacity = '0';
      }
    }
    if (routeName === 'accounting') {
      if (accountingBar) {
        accountingBar.style.opacity = '1';
      }
    } else {
      if (accountingBar) {
        accountingBar.style.opacity = '0';
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.chatUpdateSubscription?.unsubscribe();
    this.sendUnreadCount?.unsubscribe();
    this.markAsReadCount?.unsubscribe();
    this.taskUpateSubscribe?.unsubscribe();
    this.taskUpateCount?.unsubscribe();
    this.fileUpdateCount?.unsubscribe();
    this.getAllNotifications?.unsubscribe();
  }

  changeLanguage(): void {
    let lang = 'en';
    this.arabicSelected ? (lang = 'ar') : (lang = 'en');
    localStorage.setItem('NuMetric|lang', lang);
    this.languageService.setLanguage(lang);
    window.location.reload();
  }

  redirect(platfromName: string): void {
    let a = document.createElement('a');
    const token = localStorage.getItem('refresh_token');
    if (platfromName === 'payroll') {
      if (this.userSubscriptions?.find((sub) => sub?.subHost === 'Payroll')) {
        a.href = `https://payroll.numetric.work/auth/login?redirect=${token}&db=numetric&businessId=${this.selectedBusiness.businessId._id}`;
      } else {
        this.router.navigate(['/payroll']);
      }
    } else a.href = `https://learn.numetric.work/autoLogin/${token}/numetric`;
    a.target = 'blank';
    a.click();
  }

  toggleSidebar(): void {
    document
      .getElementById('secondary-sidebar')
      .classList.add('toggle-sidebar');
  }
}
