import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/shared/services/http.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { RootReducerState } from 'src/app/store/reducers';
import * as AccountsActions from 'src/app/store/actions/account.actions';

@Injectable({
  providedIn: 'root',
})
export class AccountingService {
  constructor(
    private store: Store<RootReducerState>,
    private httpService: HttpService
  ) {}

  private businessId = new BehaviorSubject(null);
  businessId$ = this.businessId.asObservable();

  setBusinessId(id): void {
    this.businessId.next(id);
  }

  setAccountsInStore(accounts): void {
    this.store.dispatch(AccountsActions.SetAccounts({ accounts }));
  }

  addAccountInStore(account): void {
    this.store.dispatch(AccountsActions.AddAccount({ account }));
  }

  updateAccountInStore(account): void {
    this.store.dispatch(AccountsActions.updateAccount({ account }));
  }

  createAccounts(data): Observable<any> {
    return this.httpService.post(
      `/account/createAccount/${data.businessId}`,
      data
    );
  }
  updateAccounts(data): Observable<any> {
    return this.httpService.put(`/account/updateAccount/${data._id}`, data);
  }
  getAllAccounts(businessId): Observable<any> {
    return this.httpService.get(`/account/getAccounts/${businessId}`);
  }

  createTransaction(data): Observable<any> {
    return this.httpService.postMultipart(
      `/transaction/addTransaction/${data.businessId}`,
      data?.formData
    );
  }

  updateTransaction(data, id): Observable<any> {
    return this.httpService.putMultipart(
      `/transaction/updateTransaction/${id}`,
      data
    );
  }

  getTransactions(businessId): Observable<any> {
    return this.httpService.get(`/transaction/getTransaction/${businessId}`);
  }

  getTransactionDetails(transactionId): Observable<any> {
    return this.httpService.get(
      `/transaction/getTransactionDetails/${transactionId}`
    );
  }

  createJournalEntry(data): Observable<any> {
    return this.httpService.post(
      `/transaction/createJournal/${data.businessId}`,
      data
    );
  }

  updateJournalEntry(data): Observable<any> {
    return this.httpService.put(`/transaction/updateJournal/${data.id}`, data);
  }

  saveJournalEntry(data, type): Observable<any> {
    switch (type) {
      case 'new':
        return this.httpService.postMultipart(
          `/transaction/createJournal/${data.businessId}`,
          data.formData
        );

      case 'old':
        return this.httpService.putMultipart(
          `/transaction/updateJournal/${data?.transactionId}`,
          data?.formData
        );
    }
  }

  markAsReviewTransaction(data): Observable<any> {
    return this.httpService.put(
      `/transaction/markAsReview/${data.transactionId}`,
      data
    );
  }

  deleteTransaction(data): Observable<any> {
    return this.httpService.delete(
      `/transaction/deleteTransaction/${data.transactionId}`,
      data
    );
  }

  filterTransactions(data, id): Observable<any> {
    return this.httpService.post(`/transaction/filterTransaction/${id}`, data);
  }

  downloadPdf(data): Observable<any> {
    return this.httpService.postPdf(`/pdf/transaction`, data);
  }
}
