import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Store, select } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { RootReducerState } from 'src/app/store/reducers';
import { selectSubscriptionPlans } from 'src/app/store/selectors/subscription.selectors';
import { selectUserSubscription } from 'src/app/store/selectors/user.selectors';
import { SubscriptionService } from './services/subscription.service';
import { ActivatedRoute } from '@angular/router';
import { StripePaymentElementComponent, StripeService } from 'ngx-stripe';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/shared/services/user.service';
import {
  StripeCardElementOptions,
  StripeElementsOptions,
} from '@stripe/stripe-js';
import { setUsersSubscription } from 'src/app/store/actions/subscription.actions';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { UserDetailsDto } from 'src/app/shared/dtos/user.dto';

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
})
export class SubscriptionsComponent implements OnInit, OnDestroy {
  allPlans = {};
  showAllSubs = false;
  subscriptions = [];
  remainingTransactionLimit: number = 0;
  storageLimit = 0;
  remainingStorage: number = 0;
  transactionPlans = [];
  storagePlans = [];
  storagePlan = null;
  currentUsage = null;
  unsubscribe$ = new Subject();
  currentSubscription = null;
  currentTransactionSubscription = null;
  currentStorageSubscription = null;
  cancelSubscriptionPlanModal = false;
  confirmSubscriptionPlanModal: boolean = false;
  starterPlanModal = false;
  showDowngradeModal = false;
  showCardModal: boolean = false;
  downgradeCheck = {
    businessLimit: true,
    transactionLimit: true,
    adminLimit: true,
    supportEmail: true,
  };
  clientSecret: string = '';
  planToSubscribe;
  business_id: string;
  planToCancel: string = '';
  @ViewChild(StripePaymentElementComponent)
  paymentElement: StripePaymentElementComponent;
  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0',
        },
      },
    },
  };

  elementsOptions: StripeElementsOptions = {
    locale: localStorage.getItem('NuMetric|lang') === 'en' ? 'en' : 'ar',
  };
  currentSubscription$: Observable<any>;
  subscripitonPlans$: Observable<any>;
  transactionLimit = 0;
  remainingLimit = 0;
  storagePercentage = 0;
  transactionPercentage = 0;
  invitePercentage = 0;
  pAndLPlan = { name: 'Budget' };
  inventoryPlan = { name: 'Inventory' };
  recurringPlan = { name: 'Recurring' };
  payrollPlan = { name: 'Payroll' };
  timeSheetPlan = { name: 'Timesheet' };
  inviteUserPlan: any = {
    name: 'InviteUser',
  };
  inviteUser: any = {
    name: 'InviteUser',
  };
  totalUser: number;
  totalRemainingUser: number;
  starterPlan = null;
  constructor(
    private subscriptionService: SubscriptionService,
    private spinner: NgxSpinnerService,
    private stripeService: StripeService,
    private toastr: ToastrService,
    private store: Store<RootReducerState>,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private userService: UserService,
    private utilService: UtilityService
  ) {
    this.currentSubscription$ = this.store.pipe(select(selectUserSubscription));
    this.subscripitonPlans$ = this.store.pipe(select(selectSubscriptionPlans));
  }
  openMarketPlace() {
    this.utilService.showHideSettingsModal.next({
      open: true,
      tab: 'marketplace',
    });
  }
  ngOnInit(): void {
    let businessId = localStorage.getItem('selectedBusiness');
    this.userService.getUserDetails(businessId).subscribe(
      (userResp: UserDetailsDto) => {
        this.totalUser = userResp.data.subscription.totalUser;
        this.totalRemainingUser =
          this.totalUser - userResp.data.subscription.remainingUser;
        this.invitePercentage =
          (this.totalRemainingUser / this.totalUser) * 100;
      },
      (error) => console.log(error.message)
    );
    this.loadSubscriptionPlans();
    this.getSubscriptionHistory();
    // this.getTransactionLimit();
  }

  getSubscriptionHistory() {
    this.subscriptionService
      .getSubscriptionHistory()
      .pipe(
        map((data) => {
          if (data?.data) {
            return data?.data.map((sub) => {
              return {
                name: sub.subscriptions?.planName,
                amount: sub.subscriptions?.planPrice,
                status: sub?.status,
                invoiceUrl: sub?.invoiceUrl,
              };
            });
          }
        }),
        catchError((error) => {
          return [];
        })
      )
      .subscribe(
        (resp) => {
          if (resp) {
            this.subscriptions = resp;
          }
        },
        (error) => {
          console.log(
            '🚀 ~ SubscriptionsComponent ~ this.subscriptionService.getSubscriptionHistory ~ error:',
            error
          );
        }
      );
  }
  getLastDate(planId) {
    let plan = this.currentSubscription?.subscriptions?.find(
      (sub) => sub?.subId === planId
    );
    return plan?.expiryDate;
  }

  updatePlan(plan, isUpgrade: boolean): void {
    if (!plan) {
      this.toastr.error('Plan not exist');
      return;
    }
    let payload = {
      planId: plan?._id,
      isUpgrade,
    };
    this.spinner.show();
    this.subscriptionService.updateSubscriptionPlan(payload).subscribe(
      (resp) => {
        if (resp?.success) {
          this.toastr.success(resp?.message);
          this.store.dispatch(
            setUsersSubscription({
              subscription: resp?.data?.currentSubscription,
            })
          );
          this.subscriptionService.setCurrentSubscription(
            resp?.data?.currentSubscription
          );
        } else {
          this.toastr.error(resp?.message);
        }
        this.spinner.hide();
      },
      (err) => {
        this.toastr.error(err?.error?.message);
        this.spinner.hide();
      }
    );
  }
  getTransactionLimit(): void {
    this.utilService.userUsage
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((currentUsage) => {
        if (currentUsage) {
          this.currentUsage = currentUsage;
          if (this.currentSubscription?.transactionLimit) {
            this.transactionLimit = currentUsage?.transactionLimit;
            this.remainingTransactionLimit =
              this.currentSubscription?.transactionLimit -
              this.transactionLimit;
            this.transactionPercentage =
              (this.remainingTransactionLimit /
                this.currentSubscription?.transactionLimit) *
              100;
          }
          if (this.currentSubscription?.totalStorage) {
            this.storageLimit = currentUsage?.remainingStorageInBytes;
            const totalStorageInBytes =
              this.currentSubscription?.totalStorage * Math.pow(1024, 3);
            this.remainingStorage = totalStorageInBytes - this.storageLimit;
            this.storagePercentage =
              (this.storageLimit / totalStorageInBytes) * 100;
            this.storageLimit = this.storageLimit / Math.pow(1024, 3);
          }
        }
      });
  }
  loadCurrentSubscription(plans): void {
    this.getTransactionLimit();
    this.currentSubscription$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((subscription) => {
        console.log('earlerSubscription', subscription);

        if (subscription?.subscriptionId) {
          this.currentSubscription = subscription;
          if (subscription?.planName === 'Starter') {
            this.starterPlan = subscription;
          }
          this.currentTransactionSubscription =
            subscription?.subscriptions?.find(
              (sub) => sub.subHost === 'Transaction'
            );
          this.currentStorageSubscription = subscription?.subscriptions?.find(
            (sub) => sub.subHost === 'Storage'
          );
          subscription?.subscriptions?.forEach((sub) => {
            if (sub.subHost === 'Budget') this.pAndLPlan = sub;
            else if (sub.subHost === 'Inventory') this.inventoryPlan = sub;
            else if (sub.subHost === 'Recurring') this.recurringPlan = sub;
            else if (sub.subHost === 'Payroll') this.payrollPlan = sub;
            else if (sub.subHost === 'InviteUser') this.inviteUserPlan = sub;
            else if (sub.subHost === 'Timesheet') this.timeSheetPlan = sub;
          });

          if (this.storagePlans) {
            this.storagePlan = this.storagePlans.find(
              (plan) => plan._id !== this.currentStorageSubscription?.subId
            );
          }
          this.subscriptionService.setCurrentSubscription(
            this.currentSubscription
          );
          this.getTransactionLimit();
        }
        if (subscription?.subId) {
          this.spinner.show();
          this.subscriptionService
            .getSubscriptionInfo({ subId: subscription.subId })
            .subscribe(
              (resp) => {
                this.spinner.hide();
                const planId = resp.data?.plan_id;
                if (planId) {
                  this.currentSubscription = { ...this.currentSubscription };
                  this.subscriptionService.setCurrentSubscription(
                    this.currentSubscription
                  );
                  this.getTransactionLimit();
                }
              },
              (error) => {
                this.spinner.hide();
                console.log('Error', error);
              }
            );
        }
      });
  }
  changeStoragePlan(plan) {
    if (this.currentStorageSubscription) {
      const isUpgrade =
        plan?.planPrice > this.currentStorageSubscription?.planPrice;
      this.updatePlan(plan, isUpgrade);
    } else {
      plan['name'] = plan.planName;
      this.showConfiramtionDialoge(plan);
    }
  }
  changeTransactionPlan(plan, isUpgrade) {
    if (this.currentTransactionSubscription) {
      this.updatePlan(plan, isUpgrade);
    } else {
      plan['name'] = plan.planName;
      this.showConfiramtionDialoge(plan);
    }
  }

  loadSubscriptionPlans(): void {
    this.spinner.show();
    this.subscripitonPlans$.subscribe(
      (plans) => {
        this.spinner.hide();
        if (plans.length > 0) {
          console.log('current subscription', plans.length);
          this.subscriptionService.setSubscriptionPlans(plans);
          this.transactionPlans = plans
            ?.filter((plan) => plan?.subscriptionHost == 'Transaction')
            ?.sort((a, b) => a.planPrice - b.planPrice);
          this.storagePlans = plans.filter(
            (plan) => plan?.subscriptionHost === 'Storage'
          );
          this.allPlans = plans?.reduce((prev, curr) => {
            if (['Storage', 'Transaction'].includes(curr.subscriptionHost)) {
              return Object.assign({}, prev, { [curr.planName]: curr });
            }
            return Object.assign({}, prev, { [curr.subscriptionHost]: curr });
          }, {});
          this.loadCurrentSubscription(plans);
        }
      },
      (error) => {
        this.spinner.hide();
        console.log(error);
      }
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  showConfiramtionDialoge(planToSubscribe): void {
    this.confirmSubscriptionPlanModal = true;
    this.planToSubscribe = this.allPlans[planToSubscribe.name];
  }

  getSubscriptionLink(): void {
    let body = {
      planId: this.planToSubscribe?._id,
    };
    this.spinner.show();
    this.subscriptionService.getSubscriptionLink(body).subscribe(
      (resp) => {
        if (resp?.success) {
          if (!resp?.data?.isCard) {
            this.elementsOptions.clientSecret = resp?.data?.clientSecret;
            this.clientSecret = resp?.data?.clientSecret;
            this.showCardModal = true;
          } else {
            this.toastr.success(resp?.message);
            this.store.dispatch(
              setUsersSubscription({
                subscription: resp?.data?.currentSubscription,
              })
            );
            this.subscriptionService.setCurrentSubscription(
              resp?.data?.currentSubscription
            );
          }
          this.confirmSubscriptionPlanModal = false;
        } else {
          this.toastr.error(resp?.message);
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(error?.error?.message);
      }
    );
  }
  pay() {
    this.spinner.show();
    this.stripeService
      .confirmSetup({
        elements: this.paymentElement.elements,
        redirect: 'if_required',
      })
      .subscribe((result: any) => {
        console.log('Result', result);
        if (result.error) {
          this.toastr.error(result?.error?.message);
          this.spinner.hide();
        } else {
          // The payment has been processed!
          if (result.setupIntent.status === 'succeeded') {
            const payload = {
              paymentId: result?.setupIntent?.payment_method,
              planId: this.planToSubscribe,
            };
            this.subscriptionService.createPaymentMethod(payload).subscribe(
              (resp) => {
                if (resp?.success) {
                  this.spinner.hide();
                  this.toastr.success(resp?.message);
                  this.showCardModal = false;
                  this.planToSubscribe = '';
                  this.store.dispatch(
                    setUsersSubscription({
                      subscription: resp?.data?.currentSubscription,
                    })
                  );
                  this.subscriptionService.setCurrentSubscription(
                    resp?.data?.currentSubscription
                  );
                } else {
                  this.spinner.hide();
                  this.toastr.error(resp?.message);
                }
              },
              (err) => {
                this.spinner.hide();
                this.toastr.error(err?.error?.message);
              }
            );
          }
        }
      });
  }
}
