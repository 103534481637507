<span class="relative inline-block group dropdown-class">
  <ng-content select="[button]"></ng-content>
  <div
    [ngClass]="{
      'hidden': !open,
      'w-28': isFromSettings,
      'w-40': !isFromSettings
    }"
    class="bg-white absolute z-10 ltr:left-1/2 rtl:right-1/2 transform ltr:-translate-x-1/2 rtl:translate-x-1/2 mt-2 right-4 rounded-xl dropdown-shadow focus:outline-none dropdown-options-class"
  >
    <ng-content select="[options]"></ng-content>
  </div>
</span>
