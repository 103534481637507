// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  // apiBaseURL: 'http://localhost:3001',
  apiBaseURL: 'https://sa.api.numetric.work',
  // apiBaseURL: 'https://api.numetric.co',
  // frontBaseURL: 'http://localhost:4200',
  frontBaseURL: 'https://sa.numetric.work',
  // stripePublishKey:'pk_test_51NLpH2FIjrLDlXyw3yRuZl6RkR52H9q4KKx5AaRsC9575wGnjG1r0XB7NrPsXX29WXPhBfTlcnTEiZpShR1pJPz8004lYAOQnj',
  stripePublishKey:
    'pk_live_51NLpH2FIjrLDlXywbsgcMF5kzhjdp53lwO5IcqZbIPU8AOkH9NGhRHA2gdDWSXuodujFo1t97xTRqIZMIUskOZ8400jucO7Sra', // live key

  // versionCheckUrl: 'https://localhost:4200/version.json'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
