import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { setUsersSubscription } from 'src/app/store/actions/subscription.actions';
import {
  UpdateAdminLimit,
  UpdateBusinessLimit,
} from 'src/app/store/actions/usage.action';
import {
  SetUser,
  UserEmailRemove,
  UserLogout,
} from 'src/app/store/actions/user.actions';
import { RootReducerState } from 'src/app/store/reducers';
import { selectUserSubscription } from 'src/app/store/selectors/subscription.selectors';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  toolTipDisabled = new BehaviorSubject(false);

  constructor(
    private httpService: HttpService,
    private store: Store<RootReducerState>,
    private router: Router
  ) {}

  getUserDetails(businessId = '', isPos = false): Observable<any> {
    return this.httpService
      .get(
        '/users/getUserDetails',
        businessId ? { businessId, isPos } : { isPos }
      )
      .pipe(catchError((error) => throwError(error)));
  }

  getBasicUserDetails(userId): Observable<any> {
    return this.httpService
      .get(`/users/getUserBasicDetails/${userId}`)
      .pipe(catchError((error) => throwError(error)));
  }

  getUserDetailsById(id): Observable<any> {
    return this.httpService.get(`/users/getInviteUser/${id}`);
  }

  getGlobalSearchResult(id, data): Observable<any> {
    return this.httpService.get(`/dashboard/globalSearch/${id}?search=${data}`);
  }

  onGetUserDetailsSuccess(user): void {
    this.setUserDetails(user);
  }

  onGetUserDetailsFailure(): void {
    this.router.navigate(['/auth/login']);
  }

  updateUserDetails(data): Observable<any> {
    return this.httpService
      .put('/users/updateUser', data)
      .pipe(catchError((error) => throwError(error)));
  }

  onUpdateUserDetailsSuccess(user): void {
    this.setUserDetails(user);
  }

  setUserDetails(user): void {
    const token = localStorage.getItem('auth_token');
    const refreshtoken = localStorage.getItem('refresh_token');
    this.store.dispatch(SetUser({ user: { ...user, token, refreshtoken } }));
  }

  setUserLimits(user) {
    if (user?.subscription.subscriptionId) {
      this.store.dispatch(
        setUsersSubscription({ subscription: user.subscription })
      );
      this.store.select(selectUserSubscription).subscribe(({ features }) => {
        console.log('features', features);

        console.log(features, user?.currentUsage);
        this.store.dispatch(
          UpdateBusinessLimit({
            businessLimit: user?.currentUsage?.businessLimit,
            subscriptionBusinessLimit: features?.businessLimit,
          })
        );
        this.store.dispatch(
          UpdateAdminLimit({
            adminLimit: user?.userEmails?.length,
            subscriptionAdminLimit: features?.adminLimit,
          })
        );
      });
    }
  }

  setUserDetailsWithToken(user): void {
    localStorage.setItem('auth_token', user.user.token);
    localStorage.setItem('refresh_token', user.user.refreshtoken);
    this.store.dispatch(SetUser(user));
  }

  addNewEmail(email): Observable<any> {
    return this.httpService
      .post('/users/addNewEmail', { email })
      .pipe(catchError((error) => throwError(error)));
  }

  deleteEmail(data): Observable<any> {
    return this.httpService
      .post('/users/removeEmails', data)
      .pipe(catchError((error) => throwError(error)));
  }

  deleteEmailInStore(email): void {
    this.store.dispatch(UserEmailRemove({ email }));
  }

  updateEmailNotifications(data): Observable<any> {
    return this.httpService.post('/users/setEmailNotification', data);
  }
  uploadProfile(data): Observable<any> {
    return this.httpService.post('/users/uploadProfile', data);
  }

  uploadBusinessLogo(data): Observable<any> {
    return this.httpService.putMultipart('/business/uploadBusinessLogo', data);
  }

  getBusinessUsers(id): Observable<any> {
    return this.httpService.get(`/users/getBusinessUsers/${id}`);
  }

  previousRouting(): void {
    let toNavigate = localStorage.getItem('currentLocation') || '/dashboard';
    let queryParams = null;
    if (toNavigate.includes('?')) {
      if (toNavigate?.split('?')[1]?.split('=').length > 1) {
        const key = toNavigate?.split('?')[1]?.split('=')[0];
        const value = toNavigate?.split('?')[1]?.split('=')[1];
        queryParams = { [key]: value };
      }
      toNavigate = toNavigate?.split('?')[0];
    }
    console.log(toNavigate);
    this.router.navigate([toNavigate], { queryParams });
    return;
  }

  checkUserSubscription(user): void {
    if (user?.businesses?.length > 0) {
      if (user?.businesses?.length === 1) {
        localStorage.setItem(
          'selectedBusiness',
          user?.businesses[0]?.businessId?._id
        );
      }
      if (user?.businesses?.length > 1) {
        this.router.navigate(['/auth/select-business']);
      } else {
        this.previousRouting();
      }
    } else {
      if (!localStorage.getItem('auth_token')?.length) {
        this.router.navigate(['/auth/login']);
      } else {
        this.router.navigate(['/auth/personal-details']);
      }
    }
  }

  logout(): void {
    localStorage.removeItem('auth_token');
    localStorage.removeItem('selectedBusiness');
    this.store.dispatch(UserLogout());
    this.router.navigate(['/auth/login']);
    localStorage.removeItem('currentLocation');
  }

  logoutAPI(): Observable<any> {
    return this.httpService.delete('/users/logout');
  }

  getAuthToken(): string {
    return localStorage.getItem('auth_token');
  }

  refreshToken(data): Observable<any> {
    return this.httpService.post('/users/refreshToken', data);
  }

  setCommunicationLanguage(data): Observable<any> {
    return this.httpService
      .put(`/users/allowLanguage`, data)
      .pipe(catchError((error) => throwError(error)));
  }
}
