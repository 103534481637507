import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-table-container',
  templateUrl: './table-container.component.html',
  styleUrls: ['./table-container.component.scss'],
})
export class TableContainerComponent implements OnInit, OnChanges {
  constructor() {}

  @Input() tableHeadings: string[] = [];
  @Input() tableData = [];
  @Input() actionTemplate;
  @Input() keys = [];
  @Input() emptyTableMessage = '';

  page = 1;

  ngOnInit(): void {
    // if (this.tableData[0]) {
    //   this.keys = Object.keys(this.tableData[0]);
    //   console.log(this.keys);
    // }
  }

  ngOnChanges(data: SimpleChanges): void {
    // if (data?.tableData?.currentValue[0]) {
    //   this.keys = Object.keys(data.tableData.currentValue[0]);
    //   console.log(this.keys);
    // }
  }
}
