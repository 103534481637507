import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { FileUploadService } from '../../services/file-upload.service';
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { selectUserSubscription } from 'src/app/store/selectors/subscription.selectors';
import { Store, select } from '@ngrx/store';
import { RootReducerState } from 'src/app/store/reducers';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploadComponent implements OnInit, OnChanges, OnDestroy {
  unsubscribe$ = new Subject();
  currentSubscription$: Observable<any>;
  userSubscriptions;
  haveStorageSubscription: boolean = true;
  typeObj = {
    'image/jpeg': 'jpeg',
    'image/png': 'png',
    'application/pdf': 'pdf',
    'application/xml': 'xml',
    'text/csv': 'csv',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      'docx',
    'application/msword': 'doc',
  };

  tableKeys: string[] = ['name', 'size', 'type', 'lastModified'];
  tableHeadings: string[] = [
    'File Name',
    'File Size',
    'File Type',
    'Last Modified',
    'Action',
  ];
  @Input() type: 'full' | 'compact' = 'full';
  @Input() tableType: 'old' | 'new' = 'old';
  @Input() className = '';
  @Output() emitter = new EventEmitter();
  @Output() onUpload = new EventEmitter();
  @Input() addedFiles? = [];
  files: File[] = [];
  filesArrToDisplay = [];

  fileToDelete = null;
  showDeleteModal: boolean = false;

  constructor(
    private toastr: ToastrService,
    private fileService: FileUploadService,
    private spinner: NgxSpinnerService,
    private changeRef: ChangeDetectorRef,
    private store: Store<RootReducerState>
  ) {
    this.currentSubscription$ = this.store.pipe(select(selectUserSubscription));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['addedFiles']?.currentValue?.length) {
      this.filesArrToDisplay = changes['addedFiles'].currentValue?.map(
        (file) => {
          return {
            _id: file?._id,
            name: file?.displayFileName,
            size:
              file.memory / (1024 * 1024) < 1
                ? (file?.memory / 1024).toFixed(2) + 'Kb'
                : (file?.memory / (1024 * 1024)).toFixed(2) + 'Mb',
            type: file?.fileType,
            lastModified: moment(file?.updatedAt).format('DD-MM-YYYY'),
            existing: true,
          };
        }
      );
    }
  }

  ngOnInit(): void {
    this.loadSubscription();
    this.fileService.emitFiles
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((emitFiles) => {
        if (emitFiles) {
          this.emitFiles();
        }
      });
  }

  loadSubscription(): void {
    this.currentSubscription$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((subscription) => {
        console.log(subscription);
        this.userSubscriptions = subscription;
        if (
          !subscription?.subscriptions?.find(
            (sub) => sub?.subHost === 'Storage'
          )
        ) {
          this.haveStorageSubscription = false;
        }
      });
  }

  addNewFile(event): void {
    if (!this.haveStorageSubscription) {
      event.preventDefault();
      return;
    }
    console.log(event?.target?.files);
    const file: File = event?.target?.files[0];
    if (file && !this.typeObj[file.type]) {
      this.toastr.error('Please select file of allowed types only');
      return;
    }
    if (file.size / (1024 * 1024) > 10) {
      this.toastr.error('File size should be less than 10 MB');
      return;
    }

    if (
      this.files.findIndex(
        (file: File) => file.name === event?.target?.files[0]?.name
      ) === -1
    ) {
      this.files.push(file);
      this.filesArrToDisplay.push({
        name: event?.target?.files[0]?.name,
        size:
          (event?.target?.files[0] as File).size / (1024 * 1024) < 1
            ? ((event?.target?.files[0] as File).size / 1024).toFixed(2) + 'Kb'
            : ((event?.target?.files[0] as File).size / (1024 * 1024)).toFixed(
                2
              ) + 'Mb',
        type: this.typeObj[
          (event?.target?.files[0] as File).type
        ].toLocaleUpperCase(),
        lastModified: moment(
          (event?.target?.files[0] as File).lastModified
        ).format('DD-MM-YYYY'),
        existing: false,
      });
      console.log(this.files);
      this.onUpload.emit();
    }
  }

  showRemoveFileConfirmation(item): void {
    this.fileToDelete = item;
    this.showDeleteModal = true;
  }

  removeFile(): void {
    if (this.fileToDelete?.existing) {
      this.deleteFile(this.fileToDelete);
    } else {
      let index = this.filesArrToDisplay.findIndex(
        (el) => el?.name === this.fileToDelete?.name
      );
      this.filesArrToDisplay.splice(index, 1);
      let filesIndex = this.files.findIndex(
        (el) => el?.name === this.fileToDelete?.name
      );
      this.files.splice(filesIndex, 1);
      console.log(filesIndex, this.fileToDelete, this.files);
      this.showDeleteModal = false;
    }
  }

  deleteFile(item): void {
    this.spinner.show();
    this.fileService
      .deleteFile(item?._id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp) => {
          console.log(resp, resp?.success);
          if (resp?.success) {
            this.showDeleteModal = false;
            this.changeRef.markForCheck();
            console.log('inside if', this.showDeleteModal);
            let index = this.filesArrToDisplay.findIndex(
              (el) => el?.name === item?.name
            );
            this.filesArrToDisplay.splice(index, 1);
            let addedFileIndex = this.addedFiles?.findIndex(
              (el) => el?.name === item?.name
            );
            this.addedFiles?.splice(addedFileIndex, 1);
            this.toastr.success(resp?.message);
          } else {
            this.toastr.error(resp?.message);
          }
          this.spinner.hide();
        },
        (err) => {
          this.toastr.error(err?.error?.message);
          this.spinner.hide();
        }
      );
  }

  emitFiles(): void {
    this.emitter.emit(this.files);
  }

  ngOnDestroy(): void {
    this.files = [];
    this.filesArrToDisplay = [];
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
