<app-document-container [templateSelected]="true">
  <div
    id="template-option-one"
    #pdfContent
    class="w-56 bg-white shadow-md m-auto py-6 px-8"
    style="width: 915px; box-shadow: 0px 0px 14px #0000001a"
  >
    <div class="grid grid-cols-2">
      <div class="col-span-1">
        <h1 class="text-4xl font-bold leading-relaxed">
          {{ "Invoice" | translate }}
        </h1>
        <p class="text-base font-bold mt-4">
          {{ invoiceData?.businessDetails?.businessId?.companyName }}
        </p>
        <p class="mt-4">
          {{
            invoiceData?.businessDetails?.businessId?.address?.addressLineFirst
          }}
          <br />
          {{
            invoiceData?.businessDetails?.businessId?.address?.addressLine2
              ? invoiceData?.businessDetails?.businessId?.address
                  ?.addressLine2 + ","
              : ""
          }}
          {{
            invoiceData?.businessDetails?.businessId?.address?.city
              ? invoiceData?.businessDetails?.businessId?.address?.city + ","
              : ""
          }}
          {{ invoiceData?.businessDetails?.businessId?.address?.state || "" }}
          <br />
          {{ invoiceData?.businessDetails?.businessId?.country }}
        </p>
        <div class="grid grid-cols-5 mt-4">
          <div class="col-span-1">
            <p
              *ngIf="invoiceData?.businessDetails?.businessId?.entityId"
              class="text-base font-bold leading-tight"
            >
              {{ "Entity ID" | translate }}
            </p>
            <p
              *ngIf="invoiceData?.businessDetails?.businessId?.taxId"
              class="text-base font-bold leading-tight"
            >
              {{ "TAX ID" | translate }}
            </p>
          </div>
          <div class="col-span-1">
            <p
              *ngIf="invoiceData?.businessDetails?.businessId?.entityId"
              class="text-base leading-tight"
            >
              {{
                invoiceData?.businessDetails?.businessId?.entityId
                  ? invoiceData?.businessDetails?.businessId?.entityId
                  : "--"
              }}
            </p>
            <p
              *ngIf="invoiceData?.businessDetails?.businessId?.taxId"
              class="text-base leading-tight"
            >
              {{
                invoiceData?.businessDetails?.businessId?.taxId
                  ? invoiceData?.businessDetails?.businessId?.taxId
                  : "--"
              }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-span-1">
        <div
          *ngIf="invoiceData?.companylogo"
          class="w-[200px] h-[112px] mb-2 ms-auto"
        >
          <img [src]="invoiceData?.companylogo" alt="Logo" class="ml-auto" />
        </div>
        <div class="grid grid-cols-2 mt-4">
          <div class="col-span-1 ml-auto">
            <p class="text-base font-bold leading-tight">
              {{ "Invoice Number" | translate }}
            </p>
            <p class="text-base font-bold leading-tight">
              {{ "PO Number" | translate }}
            </p>
            <p class="text-base font-bold leading-tight">
              {{ "Invoice Date" | translate }}
            </p>
            <p class="text-base font-bold leading-tight">
              {{ "Due Date" | translate }}
            </p>
          </div>
          <div class="col-span-1">
            <p class="text-base leading-tight ltr:text-right rtl:text-left">
              {{ invoiceData?.invoiceNumber }}
            </p>
            <p class="text-base leading-tight ltr:text-right rtl:text-left">
              {{ invoiceData?.purchaseOrder }}
            </p>
            <p class="text-base leading-tight ltr:text-right rtl:text-left">
              {{ invoiceData?.invoiceDate | date }}
            </p>
            <p class="text-base leading-tight ltr:text-right rtl:text-left">
              {{ invoiceData?.dueDate | date }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-2 border-t-2 mt-8 pt-4">
      <div class="col-span-1">
        <p class="text-base leading-tight font-bold">
          {{ "Bill To" | translate }}
        </p>
        <p class="text-base leading-tight font-bold mt-1">
          {{
            invoiceData?.customerDetails?.customerId?.customerName | titlecase
          }}
        </p>
        <p class="text-sm leading-tight mt-4">
          {{
            invoiceData?.customerDetails?.customerId?.billingAddress
              ?.addressLine1
          }}
          <br />
          {{
            invoiceData?.customerDetails?.customerId?.billingAddress
              ?.addressLine2
          }}
          <br />
          {{
            invoiceData?.customerDetails?.customerId?.billingAddress?.city
              ? invoiceData?.customerDetails?.customerId?.billingAddress?.city +
                ","
              : invoiceData?.customerDetails?.customerId?.billingAddress?.city
          }}
          {{ invoiceData?.customerDetails?.customerId?.billingAddress?.state }}
          <br />
          {{
            invoiceData?.customerDetails?.customerId?.billingAddress?.postalCode
          }}
          <br />
          {{
            invoiceData?.customerDetails?.customerId?.billingAddress?.country
          }}
          <br />
          {{ invoiceData?.customerDetails?.customerId?.mobileNumber }}
        </p>
      </div>
      <div class="col-span-1">
        <img
          src="{{ invoiceData?.qrCodeWithUuid }}"
          alt="Qr code"
          class="h-36 ltr:float-right rtl:float-left"
        />
      </div>
    </div>
    <ng-container *ngIf="invoiceData?.subHeading">
      <p class="text-xs leading-tight font-bold mt-8">
        {{ "Summary" | translate }}
      </p>
      <p class="text-xs leading-tight text-justify">
        {{ invoiceData?.subHeading }}
      </p>
    </ng-container>
    <div style="min-height: 480px">
      <table class="mt-8 w-full">
        <thead>
          <tr class="border-t-2">
            <th class="ltr:text-left rtl:text-right w-1/2 text-sm py-1">
              {{ "ITEM NAME" | translate }}
            </th>
            <th class="text-sm py-1">{{ "QTY" | translate }}</th>
            <th class="text-sm py-1">{{ "PRICE" | translate }}</th>
            <th class="text-sm py-1">{{ "AMOUNT" | translate }}</th>
            <th class="text-sm py-1">{{ "TAX" | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let item of invoiceData?.items">
            <tr class="border-t-2">
              <td class="text-sm py-1">{{ item.item }}</td>
              <td class="text-sm text-center py-1">{{ item.unit }}</td>
              <td class="text-sm text-center py-1">
                {{
                  item.price
                    | currency
                      : invoiceData?.currencyDetails?.currencySymbol
                      : "symbol-narrow"
                      : number
                }}
              </td>
              <td class="text-sm text-center py-1">
                {{
                  item.unit * item.price
                    | currency
                      : invoiceData?.currencyDetails?.currencySymbol
                      : "symbol-narrow"
                      : number
                }}
              </td>
              <td class="text-sm text-center py-1" *ngIf="item?.tax?.tax">
                {{ item?.tax?.tax }}%
              </td>
              <td class="text-sm text-center py-1" *ngIf="!item?.tax?.tax">
                {{ "No Tax" | translate }}
              </td>
            </tr>
            <tr>
              <td
                colspan="5"
                class="ps-14 text-xs text-gray-900 italic mt-4 border-b-2 pb-2 text-justify leading-tight"
              >
                {{ item.itemDescription }}
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
      <div class="grid grid-cols-3 mt-4">
        <div class="col-span-2"></div>
        <div class="col-span-1">
          <div class="grid grid-cols-2 px-2">
            <div class="col-span-1">
              <p class="text-sm italic">{{ "Subtotal" | translate }}</p>
            </div>
            <div class="col-span-1">
              <p class="text-sm ltr:text-right rtl:text-left italic">
                {{
                  invoiceData?.subtotal
                    | currency
                      : invoiceData?.currencyDetails?.currencySymbol
                      : "symbol-narrow"
                      : number
                }}
              </p>
            </div>
          </div>
          <div class="grid grid-cols-2 mt-3 px-2">
            <div class="col-span-1">
              <p class="text-sm italic">{{ "Tax" | translate }}</p>
            </div>
            <div class="col-span-1">
              <p class="text-sm ltr:text-right rtl:text-left italic">
                {{
                  invoiceData?.tax
                    | currency
                      : invoiceData?.currencyDetails?.currencySymbol
                      : "symbol-narrow"
                      : number
                }}
              </p>
            </div>
          </div>
          <div
            class="grid grid-cols-2 border-t-2 border-black mt-3 p-2 pb-0 font-bold"
          >
            <div class="col-span-1">
              <p class="text-sm">{{ "Total" | translate }}</p>
            </div>
            <div class="col-span-1">
              <p class="text-sm ltr:text-right rtl:text-left">
                {{
                  invoiceData?.totalAmount
                    | currency
                      : invoiceData?.currencyDetails?.currencySymbol
                      : "symbol-narrow"
                      : number
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="pdf-footer">
      <div class="grid grid-cols-5">
        <div class="col-span-2 flex flex-col justify-end">
          <ng-container *ngIf="invoiceData?.eSign">
            <p class="text-xs font-bold leading-tight">
              {{ "Electronically Signed" | translate }}
            </p>
            <div
              class="py-5 rounded-xl items-center justify-center"
              style="max-height: 100px"
            >
              <img style="width: 250px" src="{{ invoiceData?.eSign }}" />
            </div>
          </ng-container>
        </div>
        <div class="col-span-3 flex flex-col justify-end">
          <ng-container *ngIf="invoiceData?.notesTerms">
            <p class="font-bold text-xs">
              {{ "Terms & Conditions" | translate }}
            </p>
            <p
              class="text-xs leading-tight text-justify"
              style="min-height: 100px"
            >
              {{ invoiceData?.notesTerms }}
            </p>
          </ng-container>
        </div>
      </div>
      <p
        *ngIf="invoiceData?.Footer"
        class="text-xs text-center m-4 leading-tight"
      >
        {{ invoiceData?.Footer }}
      </p>
      <div class="grid grid-cols-3 mt-4">
        <div class="col-span-1 flex" style="font-size: 7px">
          {{ "Powered by" | translate }}
          <img src="assets/images/logo.png" alt="logo" style="height: 20px" />
        </div>
        <div class="col-span-1 text-xs text-center">
          {{ "Page" | translate }} 1/1
        </div>
        <div class="col-span-1 text-xs ltr:text-right rtl:text-left">
          {{ "Issued By" | translate }} -
          {{
            invoiceData?.userId?.firstName
              ? invoiceData?.userId?.firstName +
                " " +
                invoiceData?.userId?.lastName
              : ""
          }}
        </div>
      </div>
    </div>
  </div>
</app-document-container>
