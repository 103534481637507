import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpService } from 'src/app/shared/services/http.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  constructor(private httpService: HttpService) { }

  private subscriptionPlans = new BehaviorSubject([]);
  private currentSubscription = new BehaviorSubject({subscriptionId: '', subId: ''});
  subscriptionPlans$ = this.subscriptionPlans.asObservable();
  currentSubscription$ = this.currentSubscription.asObservable();

  getPlans(): Observable<any> {
    return this.httpService.get('/subscription/getPlans');
  }

  getPayrollPlans(): Observable<any> {
    return this.httpService.get('/subscription/getNumetricPayrollPlans')
  }

  getSubscriptionLink(data): Observable<any> {
    return this.httpService.post('/subscription/subscribePlan', data);
  }

  getPayrollSubscriptionLink(data): Observable<any> {
    return this.httpService.post('/subscription/subscribeNumetricPayrollPlan', data);
  }

  subscribeStarter(data): Observable<any> {
    return this.httpService.post('/subscription/subscribeStarter', data);
  }

  getSubscriptionHistoryOfUser(): Observable<any> {
    return this.httpService.get('/subscription/getSubscriptionHistoryOfUser');
  }

  getPayrollSubscriptionHistoryOfUser(): Observable<any> {
    return this.httpService.get('/subscription/getPayrollSubscriptionHistoryOfUser');
  }

  setSubscriptionPlans(plans): void {
    this.subscriptionPlans.next(plans);
  }

  setCurrentSubscription(data): void {
    this.currentSubscription.next(data);
  }

  getSubscriptionInfo(data): Observable<any> {
    return this.httpService.post('/subscription/getSubscriptionInfo', data);
  }

  getPayrollSubscriptionInfo(): Observable<any> {
    return this.httpService.get('/subscription/getPayrollSubscriptionInfo');
  }

  subscribeFreePlan(data): Observable<any> {
    return this.httpService.post('/subscription/subscribeFreePlan', data);
  }

  createPaymentMethod(data): Observable<any> {
    return this.httpService.post('/subscription/createPaymentMethod', data)
  }

  updateSubscriptionPlan(data): Observable<any> {
    return this.httpService.put('/subscription/updateSubscribePlan', data);
  }

  updateQuantity(data): Observable<any> {
    return this.httpService.put('/subscription/updateSubscribePlanQuantity', data)
  }

  cancelPlan(planId): Observable<any> {
    return this.httpService.delete(`/subscription/cancelSubscription/${planId}`);
  }
}
