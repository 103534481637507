import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GlobalTimerDto } from './global-timer.dto';
import * as moment from 'moment';
import { TimeSheetService } from 'src/app/modules/time-sheet/time-sheet.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalTimerService {
  constructor(private timeSheetService: TimeSheetService) {}

  private timer: BehaviorSubject<GlobalTimerDto> =
    new BehaviorSubject<GlobalTimerDto>({
      customer: '',
      id: '',
      project: '',
      until: '',
      totalWorked: 0,
    });
  private showTimer: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  private totalWork: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  timer$ = this.timer.asObservable();
  showTimer$ = this.showTimer.asObservable();
  totalWork$ = this.totalWork.asObservable();

  private intervalId: NodeJS.Timeout;

  private setTimer(data: GlobalTimerDto): void {
    if (this.intervalId) this.stopTimer();
    this.timer.next(data);
    this.intervalId = setInterval(() => {
      const localTime = moment.utc(data.until).local();
      this.totalWork.next(
        Math.abs(moment().diff(localTime, 'seconds')) + data.totalWorked
      );
      if (Math.abs(moment().diff(localTime, 'seconds')) >= 43200) {
        this.hide();
        this.timeSheetService
          .stopTimeSheet(this.timer.value.id, {
            state: 'STOP',
            totalWorked: this.totalWork.value,
            until: moment().utc().format(),
          })
          .subscribe();
      }
    }, 1000);
  }

  private stopTimer(): void {
    clearInterval(this.intervalId);
  }

  show(data: GlobalTimerDto): void {
    this.setTimer(data);
    this.showTimer.next(true);
  }

  hide(): void {
    this.showTimer.next(false);
    this.stopTimer();
  }

  get totalWorked() {
    return this.totalWork.getValue();
  }
}
